import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
// import 'rxjs/add/operator/let';
import { BackofficeUser, AuthorizationData } from '../shared';


@Injectable()
export class DjangoSessionAuthenticationService /*implements AuthorizationService*/ {

  constructor(private http: HttpClient) {
    this.userLogged$ = new BehaviorSubject<BackofficeUser | undefined>(undefined);
  }

  private currentUser: any;
  private authorizationUrl = '/auth';
  private csrftoken = null;
  private userLogged$: BehaviorSubject<BackofficeUser | false>;

  private tapSetUser = tap((result: BackofficeUser) => {
    const retValue = this.setUser(result);
    this.currentUser = retValue;
    this.userLogged$.next(retValue);
  });

  private setUser(result: BackofficeUser): any {
    let retValue: BackofficeUser | false = result;
    if (result.csrf) {
      this.csrftoken = result.csrf;
    }
    // think return in backend id = false and do !user.id comparisons in components instead of having to type DjangoBaseUser | false in all
    if (result.id === -1) {
      retValue = false;
    }
    return retValue;
  }

  private _getUserLogged(): Observable<BackofficeUser> {
    return this.http.get(`${this.authorizationUrl}/logged/`).pipe(
      this.tapSetUser,
    ) as Observable<BackofficeUser>;
  }

  login(credentials: AuthorizationData): Observable<BackofficeUser> {
    const data = {
      username: credentials.username,
      password: credentials.password
    };
    return this.http.post(`${this.authorizationUrl}/login/`, data).pipe(
      this.tapSetUser,
    ) as Observable<BackofficeUser>;
  }

  passwordReset(email: string): Observable<any> {
    const data = {email};
    return this.http.post(`${this.authorizationUrl}/password-reset/`, data, {responseType: 'text'});
  }

  deleteSelfUserData(): Observable<any> {
    return this.http.post(`${this.authorizationUrl}/delete_me/`, {}).pipe(
      tap(data => this.logout())
    );
  }

  logout(): Observable<any> {
    this.userLogged$.next(undefined);
    this.currentUser = undefined;
    return this.http.get(`${this.authorizationUrl}/logout/`);
  }

  getUserLogged(): Promise<BackofficeUser | false> {
    const promise = new Promise<BackofficeUser | false>((resolve, reject) => {
        if (this.currentUser === undefined) {
          this._getUserLogged().toPromise().then(data => {
            resolve(this.setUser(data));
          });
        } else {
          resolve(this.currentUser);
        }
      }
    );
    return promise;
  }

  getUserLogged$(): Observable<BackofficeUser | false> {
    return this.userLogged$.asObservable();
  }

  canActivate(): boolean {
    if (this.currentUser) {
      return true;
    } else {
      return false;
    }
  }

  getCsrfToken(): string {
    return this.csrftoken;
  }
}
