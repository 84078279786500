import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { BackofficeUser } from '../../shared';
import { Router } from '@angular/router';
import { DjangoSessionAuthenticationService } from '../../auth/django-session-authentication.service';
import { BackofficeConfigurationModel } from '../../configuration/backoffice-configuration.model';
import { APP_CONFIG } from '../../configuration/backoffice-configuration';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @ViewChild('profile')
  profileRef: ElementRef;
  @ViewChild('profileOpt')
  profileOptRef: ElementRef;
  user$: BackofficeUser;
  today: Date;

  constructor(@Inject(APP_CONFIG) public appConfig: BackofficeConfigurationModel,
              private sessionService: DjangoSessionAuthenticationService,
              public router: Router) { }

  ngOnInit(): void {
    this.today = new Date();
    this.sessionService.getUserLogged$()
      .subscribe((response) => {
        if (response === false){
          this.router.navigate(['/login']).then(() => {});
        }
        this.user$ = response as BackofficeUser;
      });
  }

  toggleProfileOpt(): void {
    document.getElementById('profile').classList.toggle('open');
    document.getElementById('profile-ico').classList.toggle('active');
  }

  logout(): void {
    this.sessionService.logout()
      .subscribe(() => this.router.navigate(['/login']));
  }
}
