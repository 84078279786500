import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { MainLayoutComponent, SideNavComponent, HeaderComponent } from './layout';
import { DjangoSessionAuthenticationService } from './auth/django-session-authentication.service';
import { AddApiUrlInterceptor, MessagingService, AuthInterceptor,
  WithCredentialsInterceptor, HttpXsrfInterceptorMod} from './shared';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ConfigurationService } from './configuration/configuration.service';
import { APP_CONFIG, ApplicationConfiguration } from './configuration/backoffice-configuration';
import { SharedModule } from './shared/shared.module';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { HomePageComponent } from './home-page/home-page.component';
import { AuthModalService } from './shared/auth-modal/auth-modal.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    MainLayoutComponent,
    SideNavComponent,
    HomePageComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    LoadingBarModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    ProgressbarModule.forRoot(),
  ],
  providers: [
    DjangoSessionAuthenticationService,
    AuthModalService,
    MessagingService,
    {provide: APP_CONFIG, useValue: ApplicationConfiguration},
    {provide: HTTP_INTERCEPTORS, useClass: AddApiUrlInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: WithCredentialsInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: HttpXsrfInterceptorMod, multi: true},
    {
      provide: APP_INITIALIZER, useFactory: (configService: ConfigurationService) =>
        () => configService.init(), deps: [ConfigurationService], multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
