<div id="sidenav-wrapper" *ngIf="this.user">
  <div class='menu-wrap' (click)="toggleMenu()">
    <div class="menu-box">
      <div class="b1"></div>
      <div class="b2"></div>
      <div class="b3"></div>
    </div>
  </div>
  <!-- SIDE MENU -->
  <div class="sidenav">
    <a *ngIf="isAdmin() else isManager"routerLinkActive="active" class="sidenav-item c-drop pointer">
      <div class="sidenav-item-ico">
        <span class="ti-agenda"></span>
      </div>
      <div class="sidenav-item-large">
        <div>Users</div>
      </div>
      <div class="sidenav-item-dropdown">
        <a routerLink="/users/customers" routerLinkActive="active" class="sidenav-item">
          <div class="sidenav-item-ico">
            <span class="ti-id-badge"></span>
          </div>
          <div class="sidenav-item-large-drop">
            <div>Supporters</div>
          </div>
        </a>
        <hr class="menu-dropdown-line">
        <a routerLink="/users/managers" routerLinkActive="active" class="sidenav-item">
          <div class="sidenav-item-ico">
            <span class="ti-id-badge"></span>
          </div>
          <div class="sidenav-item-large-drop">
            <div>Managers</div>
          </div>
        </a>
      </div>
    </a>
    <ng-template #isManager>
      <a routerLink="/users/customers" routerLinkActive="active" class="sidenav-item">
        <div class="sidenav-item-ico">
          <span class="ti-agenda"></span>
        </div>
        <div class="sidenav-item-large">
          <div>Customers</div>
        </div>
      </a>
    </ng-template>
    <a *ngIf="isAdmin()" routerLink="/control" routerLinkActive="active" class="sidenav-item">
      <div class="sidenav-item-ico">
        <span class="ti-harddrives"></span>
      </div>
      <div class="sidenav-item-large">
        <div>Data</div>
      </div>
    </a>
  </div>
</div>
