import { Component, Inject, OnInit } from '@angular/core';
import { BackofficeUser } from '../../shared';
import { APP_CONFIG } from '../../configuration/backoffice-configuration';
import { DjangoSessionAuthenticationService } from '../../auth/django-session-authentication.service';
import { Router } from '@angular/router';
import { BackofficeConfigurationModel } from '../../configuration/backoffice-configuration.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {

  open: boolean;
  user: BackofficeUser;

  constructor(@Inject(APP_CONFIG) public appConfig: BackofficeConfigurationModel,
              private sessionService: DjangoSessionAuthenticationService,
              public router: Router) {
    this.open = true;
  }

  ngOnInit(): void {
    const subs: Subscription = this.sessionService.getUserLogged$().subscribe((response) => {
        if (typeof response !== 'boolean') {
          this.user = response;
        }
    });
  }

  toggleMenu(): void {
    document.getElementById('sidenav-wrapper').classList.toggle('open');
    this.open = !this.open;
  }

  isAdmin(): boolean {
    return (this.user.type === 'admin');
  }
}
