import { Component, Inject, OnInit } from '@angular/core';
import { AuthorizationData, BackofficeUser } from '../shared';
import { APP_CONFIG } from '../configuration/backoffice-configuration';
import { BackofficeConfigurationModel } from '../configuration/backoffice-configuration.model';
import { Router } from '@angular/router';
import { DjangoSessionAuthenticationService } from '../auth/django-session-authentication.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginData: AuthorizationData;
  user: BackofficeUser | boolean;
  errors: boolean;
  confirmation: boolean;
  state: 'login' | 'request';

  constructor(@Inject(APP_CONFIG) public appConfig: BackofficeConfigurationModel,
              private sessionService: DjangoSessionAuthenticationService,
              public router: Router) {
    this.loginData = {username: '', password: ''};
    this.errors = false;
    this.confirmation = false;
    this.state = 'login';
  }

  ngOnInit(): void {
  }

  login(): void {
    this.sessionService.login(this.loginData).subscribe(
      () => { this.router.navigate(['/users/customers'], { state: { ignoreLoadingBar: true } }); },
      (err) => { this.showError(err); }
    );
  }

  resetPassword(form: NgForm): void {
    const regexEmail = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
    const target: string = form.value.email;
    if (form.invalid || !regexEmail.test(target)) { return; }
    this.sessionService.passwordReset(target).subscribe(() => {
      this.confirmation = true;
    });
  }

  showError(error): void {
    console.error(error);
    this.errors = true;
  }
}
