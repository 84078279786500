<div class="full flex j-center mgt5">
  <!-- Show login form -->
  <div class="flex w40 j-center f-column" *ngIf="state === 'login'">
    <h2 class="weight mgb1 logintTitle">Private Area</h2>
    <div class="box-login w60">
      <form #loginForm='ngForm' (ngSubmit)="login()">
        <div class="flex f-column alignItemStart mgb1">
          <label for="username" class="m-text">Username</label>
          <input type="text" class="ip4input input full" name="username" id="username" [(ngModel)]="loginData.username" placeholder="">
        </div>
        <div class="flex f-column alignItemStart mgb2">
          <label for="password" class="m-text">Password</label>
          <input type="password" class="ip4input input full" name="password" id="password" [(ngModel)]="loginData.password" placeholder="*****">
        </div>
        <div class="alert alert-danger" role="alert" *ngIf="errors">
          User or password are incorrect
        </div>
        <button class="loginButton white" type="submit">
          Log In
        </button>
      </form>
    </div>
    <a (click)="this.state = 'request';" class="simple-link">Forgot your password?</a>
  </div>
  <!-- Show request password form -->
  <div class="flex w40 j-center f-column" *ngIf="state === 'request'">
    <h2 class="weight mgb1 logintTitle">Private Area</h2>
    <div class="box-login w60">
      <form #requestPassForm='ngForm' (ngSubmit)="resetPassword(requestPassForm)">
        <div class="flex f-column alignItemStart mgb1">
          <label for="username" class="m-text">Enter a Email</label>
          <input type="text" class="ip4input input full" name="email" id="email" [(ngModel)]="requestPassForm.value.email" placeholder="jonsnow@example.com">
        </div>
        <div class="alert alert-info" role="alert" *ngIf="confirmation">
          You will receive an email if the account exists. Please check your inbox or spam and follow the instructions.
        </div>
        <button class="loginButton white" type="submit">
          Send Email
        </button>
      </form>
    </div>
    <a (click)="this.state = 'login';" class="simple-link">Back</a>
  </div>
</div>

