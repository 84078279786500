import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BackofficeConfigurationModel } from '../configuration/backoffice-configuration.model';
import { APP_CONFIG } from '../configuration/backoffice-configuration';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {

  constructor(@Inject(APP_CONFIG) public appConfig: BackofficeConfigurationModel, public router: Router) { }

  ngOnInit(): void {
  }

  private init(): void {}

}
