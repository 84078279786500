import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpErrorResponse, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { DjangoSessionAuthenticationService } from '../../auth/django-session-authentication.service';
import { MessagingService } from '../info-modal';
import { AuthModalService } from '../auth-modal/auth-modal.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  isShown = false;

  constructor(
    private router: Router,
    private auth: DjangoSessionAuthenticationService,
    private messageService: MessagingService,
    private authModalService: AuthModalService) { }

  private handleAuthError(error: HttpErrorResponse): Observable<any> {
    // handle the auth error or rethrow
    if (error.status === 401 || error.status === 403) {
      if (this.router.url !== '/login' && !this.isShown) {
        this.isShown = true;
        this.authModalService.authModal(
          'Oops!',
          `Your session has been expired.`,
          'Accept',
          () => {
            this.isShown = false;
            this.router.navigateByUrl('/login');
          }
        );
      }
    }
    return throwError(error);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Clone the request to add the new header.


    // Commented because it's used on other interceptor
    // const headerName = 'X-CSRFTOKEN';
    // const token = this.auth.getCsrfToken() as string;
    // if (token !== null && !request.headers.has(headerName)) {
    //     request = request.clone({ headers: request.headers.set(headerName, token) });
    // }
    return next.handle(request).pipe(catchError(x => this.handleAuthError(x)));
  }
}
